<template>
  <div class="no-apps">
    <Icon type="Warning" />
    <div class="no-apps__text">
      <span>
        У вас еще нет заявок. Перейдите в раздел
        <router-link :to="{ name: 'Events' }">«Мероприятия»</router-link>
      </span>
    </div>
  </div>
</template>

<script>
import Icon from "@/components/Blocks/Icon";
export default {
  name: "NoApplications",
  components: { Icon },
};
</script>

<style lang="scss">
.no-apps {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 60px;
  gap: 15px;
  border: 1px solid #fce624;
  background-color: #fffde8;
  border-radius: 10px;
  max-width: 500px;
  margin: auto;
  i {
    color: #ffe600;
    font-size: 8rem;
  }
  &__text {
    font-size: 1.8rem;
    line-height: 2.2rem;
    color: var(--text--color);
    font-weight: 500;
  }
  a {
    text-decoration: underline;
    color: #4f46e5;
  }
  @include adaptive(phone) {
    flex-wrap: wrap;
    padding: 15px;
    i {
      font-size: 4rem;
    }
    &__text {
      text-align: center;
    }
  }
}
</style>
